@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}
body {
  background: #000;
}

h1,
h4,
p,
a {
  color: white;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}
.btn-light {
  background: transparent;
  color: #fff;
}
.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: all 0.3s ease;
}
.btn.btn-light:hover {
  background: rgb(248, 217, 15);
  color: #222;
}
