@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}
body {
  background: #000;
}

h1,
h4,
p,
a {
  color: white;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}
.btn-light {
  background: transparent;
  color: #fff;
}
.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: all 0.3s ease;
}
.btn.btn-light:hover {
  background: rgb(248, 217, 15);
  color: #222;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10;
}
.header-bg {
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.5s;
}
.nav-menu {
  display: flex;
}
.nav-menu li {
  padding: 0 1rem;
}
.nav-menu li a {
  font-size: 1.2rem;
  font-weight: 500;
}
.hamburger {
  display: none;
}
@media screen and (max-width: 1040px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-menu li {
    padding: 1rem 0;
  }
  .nav-menuli a {
    font-size: 2rem;
  }
  .hamburger {
    display: initial;
  }
}

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}
.bg-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}
.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
}
.hero {
  height: 100%;
  width: 100%;
}
.hero .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}
.hero .content h1 {
  font-size: 4rem;
  padding: 0.6rem 0 1.5rem;
}
.hero .content p {
  font-size: 1.4rem;
  font-weight: 200;
  text-transform: uppercase;
}
.content .btn {
  margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px) {
  .hero .content h1 {
    font-size: 3rem;
  }
  .hero .content p {
    font-size: 1.4rem;
  }
}

.footer {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #797979;
  padding: 15px 0;
  color: #fff;
}
.footer ul li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}

/* ---- media query ---- */

@media (max-width: 600px) {
  .footer {
    display: block;
    text-align: center;
  }
  .footer ul li {
    margin: 10px;
  }
  .footer ul {
    margin-top: 10px;
  }
}

.company {
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
}

/* ---- other styles ---- */

.company-left {
  flex-basis: 40%;
  position: relative;
}

.company-right {
  flex-basis: 56%;
}

.company-img {
  width: 100%;
  border-radius: 10px;
}

.company h2 {
  font-size: 35px;
  color: rgb(248, 217, 15);
  margin: 10px 0;
  max-width: 400px;
}

.company p {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

/* ---- media query ---- */

@media (max-width: 800px) {
  .company {
    flex-direction: column;
  }
  .company-left {
    flex-basis: 100%;
    margin: 20px;
    order: 2;
  }
  .company-right {
    flex-basis: 100%;
  }
}

.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  /* font-family: Arial, sans-serif; */
}

.question-section {
  margin-bottom: 20px;
}

.question-count {
  margin-bottom: 10px;
  font-size: 20px;
}

.question-text {
  margin-bottom: 20px;
  font-size: 24px;
}

.answer-section {
  display: flex;
  flex-direction: column;
}

.answer-section button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 18px;
}

.answer-section button:hover {
  background-color: #45a049;
}

.score-section {
  font-size: 24px;
  color: #333;
}

.quiz-with-preamble-container {
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  color: #fff;
}

.preamble-section {
  text-align: center;
  margin: auto;
  padding: 1rem;
  flex-basis: 40%;
  position: relative;
  color: #fff;
}
.quiz-with-preamble-container .preamble-section p {
  margin: 1.2rem 0;
}

.quiz-section {
  flex-basis: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.preamble-section button {
  background-color: rgb(248, 217, 15);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
}

.preamble-section button:hover {
  background-color: rgb(248, 217, 15);
}

/* ---- media query ---- */

@media (max-width: 800px) {
  .quiz-with-preamble-container {
    flex-direction: column;
  }
  .quiz-section {
    flex-basis: 100%;
    margin: 20px;
    order: 2;
  }
  .preamble-section {
    flex-basis: 100%;
  }
}

.hero-img {
  width: 100%;
  background-color: rgba(0, 0, 0, al0.5pha);
  height: 60vh;
  position: relative;
}

.hero-img::before {
  content: "";
  background-image: url(/static/media/meeting-img.6bf72d28.png);
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-img h1 {
  font-size: 2.4rem;
}
.hero-img p {
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .hero-img h1 {
    font-size: 2rem;
  }
}

.about {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-wrap: wrap;
  height: 100%;
}
.about .left {
  text-align: center;
  margin: auto;
  padding: 1rem;
  max-width: 350px;
}
.about .left p {
  margin: 1.2rem 0;
}

.about .right {
  max-width: 700px;
}

.right .img-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
  text-align: center;
}
.right .img {
  max-width: 90%;
  border: 1px solid #333;
}
.right .top {
  grid-column: 1 / span 8;
  grid-row: 1;
  padding-top: 20%;
  z-index: 6;
}
.right .bottom {
  grid-column: 4 / -1;
  grid-row: 1;
  z-index: 5;
}

.title {
    text-align: center;
    color: #80734d;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 70px 0 30px;
  }
  .title h2 {
    font-size: 32px;
    color: #000f38;
    margin-top: 5px;
    text-transform: none;
  }
  
.table-container {
  overflow-x: auto;
  margin: 20px auto;
  border: 1px solid #444;
  width: 80%;
  background-color: #1e1e1e;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2e2e2e;
  color: #e0e0e0;
  border: 1px solid #444;
}

.schedule-table th,
.schedule-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444;
}

.schedule-table th {
  background-color: #3e3e3e;
  font-weight: bold;
  color: #fff;
}

.schedule-table tr:hover {
  background-color: #3e3e3e;
}

.schedule-table .link {
  color: #1e90ff;
  text-decoration: none;
}

.schedule-table .link:hover {
  text-decoration: underline;
}

.schedule-table tr {
  height: 50px;
}

.contact {
  margin: 80px auto;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-col {
  flex-basis: 48%;
  color: #676767;
}
.contact-col h3 {
  color: rgb(248, 217, 15);
  font-size: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.contact-col h3 img {
  width: 35px;
  margin-left: 10px;
}
.contact-col p {
  max-width: 450px;
  line-height: 1.3;
}
.contact-col ul li {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.contact-col ul li img {
  width: 25px;
  margin-right: 10px;
}
.contact form input,
.contact form textarea {
  display: block;
  width: 100%;
  background: #f5f2e6;
  padding: 15px;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;
}
.contact-col span {
  display: block;
  margin: 20px 0;
}

/* ---- media query ---- */

@media (max-width: 700px) {
  .contact {
    display: block;
  }
  .contact-col {
    padding: 20px;
  }
}

