.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  /* font-family: Arial, sans-serif; */
}

.question-section {
  margin-bottom: 20px;
}

.question-count {
  margin-bottom: 10px;
  font-size: 20px;
}

.question-text {
  margin-bottom: 20px;
  font-size: 24px;
}

.answer-section {
  display: flex;
  flex-direction: column;
}

.answer-section button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 18px;
}

.answer-section button:hover {
  background-color: #45a049;
}

.score-section {
  font-size: 24px;
  color: #333;
}
