.company {
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
}

/* ---- other styles ---- */

.company-left {
  flex-basis: 40%;
  position: relative;
}

.company-right {
  flex-basis: 56%;
}

.company-img {
  width: 100%;
  border-radius: 10px;
}

.company h2 {
  font-size: 35px;
  color: rgb(248, 217, 15);
  margin: 10px 0;
  max-width: 400px;
}

.company p {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

/* ---- media query ---- */

@media (max-width: 800px) {
  .company {
    flex-direction: column;
  }
  .company-left {
    flex-basis: 100%;
    margin: 20px;
    order: 2;
  }
  .company-right {
    flex-basis: 100%;
  }
}
