.hero-img {
  width: 100%;
  background-color: rgba(0, 0, 0, al0.5pha);
  height: 60vh;
  position: relative;
}

.hero-img::before {
  content: "";
  background-image: url("../assets/meeting-img.png");
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-img h1 {
  font-size: 2.4rem;
}
.hero-img p {
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .hero-img h1 {
    font-size: 2rem;
  }
}
